.w-100{
    width: 100%;
  }
  .name-slip {
    background-color: #fff;
  }
  
  .name, .image, .school-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #000;
  }
  
  .name {
    font-size: 20px;
    font-weight: bold;
  }
  