@media only screen and (max-width: 800px) {
  .no-more-tables-font td:before {
    font-size: 10px;
  }
  .no-more-tables-font td{
    font-size: 10px;
    padding: 0px;
  }
  .mbl-table-card{
    margin-bottom: 5px;
    box-shadow: 2px 3px 4px 5px #fff;
    border-radius: 5px;
  }
    /* Force table to not be like tables anymore */
    .no-more-tables table,
    .no-more-tables thead,
    .no-more-tables tbody,
    .no-more-tables th,
    .no-more-tables td,
    .no-more-tables tr {
      display: block;
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    .no-more-tables thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    .no-more-tables tr {
      border: 1px solid #ccc;
    }
  
    .no-more-tables td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;
    }
  
    .no-more-tables td:before {
      position: absolute;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }
    /*
    Label the data
    */
    .no-more-tables td:before {
      content: attr(data-title);
    }
  }
  