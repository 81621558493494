.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
    width: 100%;
    background-color: #f9f9f9;
  }
  
  .loader-logo {
    width: 50px; /* Adjust size for logo */
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  