@import "./ComponentsWeb/Sub/color.css";
@font-face {
  font-family: 'Roboto';
  src: url('../public/font/Roboto-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Sevillana';
  src: url('../public/font/Sevillana-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Aleo Regular';
  src: url('../public/font/Aleo-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Elmessiri bold';
  src: url('../public/font/elmessiri-bold-1.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy Extrabold';
  src: url('../public/font/gilroy-extrabold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy Light';
  src: url('../public/font/gilroy-light.otf');
  font-display: swap;
}
@font-face {
  font-family: 'GrandHotel Regular';
  src: url('../public/font/GrandHotel-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Indigo Outline';
  src: url('../public/font/Indigo Outline.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Indigo Regular';
  src: url('../public/font/Indigo Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'KozGoPr6N Bold';
  src: url('../public/font/KozGoPr6N-Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'KozGoPr6N Regular';
  src: url('../public/font/KozGoPr6N-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'KozGoPro Regular';
  src: url('../public/font/KozGoPro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Lato Regular';
  src: url('../public/font/Lato-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Locomotype GigaSansRegular';
  src: url('../public/font/Locomotype GigaSansRegular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'MyriadArabic Regular';
  src: url('../public/font/MyriadArabic-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'MyriadHebrew Regular';
  src: url('../public/font/MyriadHebrew-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'MyriadPro Regular';
  src: url('../public/font/MyriadPro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansGurmukhi ExtraCondensed Regular';
  src: url('../public/font/NotoSansGurmukhi_ExtraCondensed-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'NunitoSans Regular';
  src: url('../public/font/NunitoSans-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Oswald Regular';
  src: url('../public/font/Oswald-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Pluto Regular';
  src: url('../public/font/Pluto Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Slab Regular';
  src: url('../public/font/roboto-slab.regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Trebuc';
  src: url('../public/font/Trebuc.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Trebucbd';
  src: url('../public/font/Trebucbd.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Trebucbi';
  src: url('../public/font/Trebucbi.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Trebucit';
  src: url('../public/font/Trebucit.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Amsterdam-1';
  src: url('../public/font/Amsterdam-1.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Amsterdam-2';
  src: url('../public/font/Amsterdam-2.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Amsterdam-3';
  src: url('../public/font/Amsterdam-3.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Amsterdam-4';
  src: url('../public/font/Amsterdam-4.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'GreatVibes-Regular';
  src: url('../public/font/GreatVibes-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Allura-Regular';
  src: url('../public/font/Allura-Regular.ttf');
  font-display: swap;
}
/* font end */
.input{
  transition: transform 0.2s ease-in-out, opacity 0.2s;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}
html, body {
  -webkit-text-size-adjust: 100%; 
  text-size-adjust: 100%;        
}
.font-family-sans-serif{
  font-family: sans-serif;
}
.bg-gray{
  background-color: #ccc;
}
.text-align-left tr td{
  text-align: left !important;
}
.overlay {
  pointer-events: none; 
}
.qty-discount-title{
  text-align: center;
  font-weight: 900;
  color: var(--secondText);
}
.qty-price-tab{
    background: #cde7fd;
    border-radius: 5px;
    text-align: center;
}
.bold-black{
  font-weight: bold;
  color: var(--secondText);
}
.bg-thememain {
  background: var(--themeMain);
}
.text-align-start{
  text-align: start;
}
.text-align-end{
  text-align: end;
}
.bg-themesecond {
  background-color: var(--secondColor);
}
.f-13{
  font-size: 13px !important;
}
.login-btn:hover {
  background-color: var(--secondColor) !important;
}
.p-relative{
  position: relative;
}
body {
  font-family: "Rubik", sans-serif;
}
.text-secondColor{
  color: var(--secondColor);
}
ul {
  padding: 0;
  margin: 0;
}
.text-red {
  color: red;
}
.button-pointer{
  cursor: pointer;
}
.page-go-to-top{
  position: relative !important;
  top: 0 !important;
}
button {
  border: none ;
}

.side-menu button:hover {
  background-color:var(--secondColor)!important;
  color:var(--secondText) !important;
 
}

li {
  list-style: none;
}

td {
  font-size: 15px;
}

.side-menu {
  position: fixed;
  background: linear-gradient(125deg, grey, #ad9878);
 
  width: 280px;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  transition: width 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease;
  overflow-y: auto;
  transform: translateX(-9%);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.4);
  
  perspective: 1000px;
 
}

.side-menu.inactive {
  width: 190px;
  transform: translateX(-100%);
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  perspective: 1000px;
}

.side-menu .logo {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
  /* margin-right: 50px; */
}

.side-menu .logo img {
  width: 100%;
}

/* .side-menu .logo img:hover {
  transform: scale(1.2) ;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
} */
.toggle-menu-btn {
  font-size: 32px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff;
  transition: color 0.4s ease, transform 0.4s ease;
}

.side-menu.inactive .toggle-menu-btn {
  right: -50px;
}

.toggle-menu-btn:hover {
  color: #ffc371;
  transform: translateY(-50%) rotateY(-15deg) scale(1.2);
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.nav-items {
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 2px 20px;
  border-radius: 15px;
  transition: background-color 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease;
  color: #fff;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
}

/* .nav-items:hover {
  background-color:  #ff8e01;
  transform: translateX(15px) rotateY(5deg);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
} */

.icon {
  font-size: 1.5rem;
  margin-right: 15px;
  transition: color 0.4s ease, transform 0.4s ease;
}

.nav-item:hover .icon {
  color: #ffc371;
  transform: scale(1.2) rotateY(5deg);
}

.text {
  flex: 1;
  transition: transform 0.4s ease;
}

.nav-item:hover .text {
  transform: translateX(10px);
}

.divider {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.custom-input, .custom-input:focus {
  border: none !important;
  font-size: 13px;
  padding: 0;
  outline: none !important;
  box-shadow: none !important;
}

.custom-input-main {
  border-bottom: 2px solid var(--bs-danger);
}
.mbl-site-logo{
  width: 45%;
  object-fit: contain;
}
@media (max-width: 700px) {
  .mbl-site-logo{
    width: 65%;;
    background-size: contain;
  }
  .qty-offer-scrolling{
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
  .qty-offer-scrolling-col{
    width: 40%;
  }
  .side-menu {
    width: 100%;
    max-width: 320px;
    transform: translateX(10);
  }
  .ds {
    display: none;
  }

  .side-menu.inactive {
    transform: translateX(-100%);
  }

  .top-section .logo {
    width: 75px;
  
  }
  .top-section .logo img{
    width: 75px;
   

  }

  .toggle-menu-btn {
    font-size: 28px;
  }

  .nav-items {
    font-size: 14px;
    padding: 10px 15px;
  }
}


.table-scrolling {
     -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    padding: 0;
    box-sizing: border-box;
  
    margin: 0 auto;
   
}

.font-weight-bold {
  font-weight: bold;
}

.product-name-box {
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-70{
  width: 70% !important;
}
@media only screen and (max-width: 600px) {
  .position-fixed{
    position: fixed;
    z-index: 9999;
  }
  .top-2{
    top: 10px;
  }
  .m-h-50 {
    height: 45vh;
  }
  .reverse{
    flex-direction: row-reverse;
  }
  .input-h-m{
    height: 24px !important;
  }
  .mbl-fixed-btn{
    bottom: 0;
    position: fixed;
    width: 102%;
    z-index: 99999;
  }
  .personalize-btn-mbl{
    border: 1px solid #fff !important;
    background: var(--themeMain);
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px 1px 14px 3px #000;
    padding: 10px 0px;
  }
  .add-to-cart-mbl{
    padding: 0px;
    bottom: 0;
    position: fixed;
    left: 0%;
    z-index: 11;
  }
  .buy-now-mbl{
    padding: 0px;
    bottom: 0;
    position: fixed;
    left: 50%;
    z-index: 11;
  }
  .btn-addToCart-buyNow-mbl{
    border-radius: 0;
    box-shadow: 1px 4px 13px 0px #000;
    padding: 13px 0px;
  }
  .user-mbl-img-input{
    max-width: 50%;
    max-height: 100px;
    object-fit: contain;
  }
}

@media only screen and (min-width: 600px) {
  .height-100vh {
    height: 100vh;
    overflow: hidden;
  }
  .reward-offer {
    height: 3vh;
  }

  .desktop-hide {
    display: none;
  }

  
  .all-calculated-amount-tbl li {
    font-size: 18px;
    font-weight: 500;
  }

  .logoForm {
    width: 30% !important;
  }
}

.menu-icon {
  display: inline-block;
}

.width-auto {
  width: auto;
}

.f-right {
  float: right;
}

.invalid-feedback {
  text-transform: capitalize;
}

.first-latter-capital {
  text-transform: capitalize;
}

.content {
  background: #F8FAFB;
  width: calc(100% - 200px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.content-area {
  min-height: 100vh;
  transition: all 0.3s;
  top: 10%;
  right: 0;
}

.content-active {
  width: 94%;
  position: absolute;
  left: 6%;
  overflow: hidden;
}

.dropdown-menu[data-bs-popper] {
  left: -80%;
}

.mt-extra {
  margin-top: 4.25rem !important;
}

.card-width {
  width: 21rem;
}

.margin-left {
  margin-left: 25%;
}

.margin-m-left {
  margin-left: 5%;
}

/* //login form Css */
a {
  text-decoration: none;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}

.align-item-center {
  align-items: center;
}

.form-right i {
  font-size: 100px;
}

.nav-link {
  font-size: 16px;
  padding: 0px;
}

hr {
  margin: 0.5rem 0;
}

.dashboard-bottom {
  height: 1px;
  width: 100px;
  background-color: var(--themeMain);
}
.word-wrap-break{
  word-wrap: break-word;
}
.bg-side {
  background:linear-gradient(300deg, rgb(0, 100, 139), rgb(28, 116, 187),rgb(64, 64, 161),rgb(19, 126, 158),rgb(2, 228, 209));
}

.display-n {
  display: none;
}

.hide-side-menu {
  display: none;
}

.bg-thememain {
  background: var(--themeMain);
}

.sideMenuLinks {
  padding: 6px 5px;
  color: var(--firstText);
}

/* .nav-link{
color: #fff;
} */
.selectedBox {
  background-color: var(--success);
  color: var(--firstText);
}

.sub-tbl tr,
th,
td {
  border-style: solid;
  border-width: 1px;
  padding: 0px 2px;
}

.over-col-size {
  width: 14%;
  
}

.tables{
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  font-weight: unset;
  padding-right: 10px;
  padding-left: 40px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  background-color: #6c7ae0;
}
.tbody-table{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-collapse: collapse;
   
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #dbd9dfa9;
}
.table>:not(caption)>*>* {
  padding: 0.2rem 0.2rem !important;
}

.my-profile-content p {
  font-size: 20px;
}

.my-profile-content {
  box-shadow: 1px 2px 5px 0px;
}

.nav-item {
  color: var(--firstText) !important;
}


.nav-link:focus,
.nav-link:hover {
  color: var(--themeMain);
}

.side-space {
  margin-left: 20px;
}

.side-menu .active {
  color: var(--themeMain) !important;
  background-color: var(--sidecolor);
}

.demo a .active {
  color: var(--secondColor) !important;
  background-color: var(--sidecolor);
}


@media screen and (max-width: 1100px) {
  table {
    overflow-x: auto;
    overflow-y: auto;
    display: block;
  }

}

table p {
  font-size: 14px !important;
}

/* //responsive design */
@media (min-width: 320px) and (max-width: 480px) {
  .productsreview .nav-link {
    padding: 6px !important;
    font-size: 14px !important;
    margin-right: 0px !important;
  }

  .w-70 {
    width: 70%;
  }

  .p-mobile {
    margin-top: 10px;
    margin-left: -50px;
  }

  .mbt-phone {
    margin-left: -30px;
    margin-top: 10px;
  }

  .mb-phone {
    margin-bottom: 10px;
  }

  .side-menu {
    width: 33%;
  }

  .side-menu .logo {
    width: 70px;
  }

  .content {
    width: calc(67%);
  }

 

  .nav-link {
    font-size: 14px;
    padding: 0px;
  }

  .nav.fs-3.mt-1.flex-column {
    margin-left: -8px;
    width: 100%;
  }

  .mbl-none {
    display: none;
  }
}

/* //responsive design */
.notFountPage {
  text-align: center;
  width: 50%;
  margin: auto;
  /* margin-top: 30vh; */
  background: #ccc;
  padding: 50px 25px;
}

.tbl-img {
  height: 50px;
  border-radius: 50%;
  width: auto !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.auto-scrolling-tbl {
  overflow: auto;
  height: 70vh;
}

.float-right {
  float: right;
}

.shipping-cost-tab {
  background: var(--secondColor);
  text-align: center;
}

.image img {
  width: auto !important;
}

.blink_img {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.fixed-whatsapp {
  position: fixed;
  bottom: 0;
  right: 6px;
  background: #25ca25;
  color: #fff;
  padding: 4px 10px;
  font-size: 25px;
  border-radius: 50%;
  z-index: 99999;
}
.clear-btn{
  position: fixed;
  bottom: 64px;
  right: 0;
  font-size: 16px;
  padding: 0px 5px;
  border-radius: 10px 0px 0px 10px;
  background: var(--secondColor);
  color: var(--secondText);
  z-index: 999999;
}
.singIn {
  background: #ff832b;
  padding: 2px 5px;
  border-radius: 8px;
  color: #0b2b42;
  font-weight: 600;
}

.small-font {
  font-size: 15px;
}

.w-40 {
  width: 40% !important;
}

.align-item-center {
  align-items: center;
}

.f-18 {
  font-size: 18px;
}
.f-16{
  font-size: 16px;
}
.w-75 {
  width: 75%;
}
.theme-btn{
  background-color: var(--themeMain);
}
.theme-btn:hover{
  background-color: var(--secondColor);
}
.user-cart-item {
  background-color: #ccc;
}
.discount-price{
    text-decoration: line-through;
    font-size: 18px;
}
.offer-desc p {
  font-size: 18px;
}

.list-style-dot li {
  list-style-type: disc;
}

.reward-offer {
  color: #cc0c39;
}

.reward-offer span {
  background-color: #cc0c39;
  color: #fff;
  padding: 4px;
}
.reward-offer-single{
  text-align: center;
  background-color: #cc0c39;
  color: #fff;
  padding: 7px 4px;
}
.w-15-percent{
  width: 15%;
}
.accordion-button{
  font-size: 25px;
  color: var(--firstText) !important;
  background: var(--themeMain) !important;
}
.modal-bottom .modal-content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px; 
  margin: 0;
}
.name-slip-bg{
  background-size: contain !important;
}
.product-bg-container{
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.w-80{
  width: 80%;
}
.gap-10{
  gap: 10px;
}
.font-12{
  font-size: 12px;
}
.opacity{
  opacity:0;
}
/* add item in cart */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
}
.loader-spinner {
  border: 8px solid var(--secondColor); 
  border-top: 8px solid var(--themeMain); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading-text {
  color: var(--themeMain);
  font-size: 1.5rem;
  margin-top: 10px;
}
/* end add item in cart */
.mobile-page {
  position: relative;
  height: 100vh;
  /* overflow-y: scroll;
  display: flex; */
  flex-direction: column;
  align-items: center;
}
.inputs-container {
  /* margin-top: 220px; */
  padding: 20px;
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  max-width: 400px;
}
.keyboard-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px; 
  background-color: #333;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}
/* Dark mode base */
/* Futuristic Dashboard Design */
/* .dashboard-container {
  background: linear-gradient(135deg, #0d0d0d, #222);
  padding: 50px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* Glitchy Neon Header */
/* .dashboard-header {
  font-family: 'Audiowide', sans-serif;
  font-size: 3.5rem;
  color: #0ef;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 40px;
  position: relative;
}


.dashboard-header::before, 
.dashboard-header::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #0ff;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: glitch 3s infinite;
}

.dashboard-header::after {
  color: #f0f;
  animation: glitch 3s infinite reverse;
}

@keyframes glitch {
  0% {
    clip: rect(100px, 9999px, 150px, 0);
  }
  10% {
    clip: rect(0, 9999px, 80px, 0);
  }
  20% {
    clip: rect(20px, 9999px, 40px, 0);
  }
  30% {
    clip: rect(90px, 9999px, 150px, 0);
  }
  40% {
    clip: rect(50px, 9999px, 120px, 0);
  }
  50% {
    clip: rect(10px, 9999px, 60px, 0);
  }
  60% {
    clip: rect(100px, 9999px, 150px, 0);
  }
  70% {
    clip: rect(30px, 9999px, 60px, 0);
  }
  80% {
    clip: rect(50px, 9999px, 100px, 0);
  }
  90% {
    clip: rect(20px, 9999px, 80px, 0);
  }
  100% {
    clip: rect(60px, 9999px, 120px, 0);
  }
} */


/* Futuristic Cards with Animated Borders */
/* .cards {
  background: rgba(28, 28, 28, 0.8);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 2px solid transparent;
  background-clip: padding-box;
  margin-bottom: 30px;
  height: 180px;
}

.cards::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, rgb(0, 100, 139), rgb(28, 116, 187),rgb(64, 64, 161),rgb(19, 126, 158),rgb(2, 228, 209));
  background-size: 400%;
  z-index: -1;
  opacity: 0.6;
  animation: borderGlow 8s infinite linear;
  filter: blur(4px);
}



.cards:hover {
  transform: scale(1.07);
  box-shadow: 0 15px 40px rgba(0, 255, 255, 0.4);
}

.cards-body h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 3rem;
  color: #0ef;
  margin-bottom: 10px;
  letter-spacing: 2px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    text-shadow: 0 0 8px #0ef, 0 0 12px #00f, 0 0 20px #0ff;
  }
  100% {
    text-shadow: 0 0 15px #0ef, 0 0 25px #00f, 0 0 35px #0ff;
  }
}

.cards-body p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}


@media (max-width: 768px) {
  .dashboard-header {
    font-size: 2.5rem;
  }

  .cards {
    padding: 20px;
    margin-bottom: 20px;
  }

  .cards-body h2 {
    font-size: 2rem;
  }

  .cards-body p {
    font-size: 1rem;
  }
} */
.dboard .mt-extra {
  background-color: var(--grayBg);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.dboard .dashboard-bottom {
  border-bottom: 2px solid var(--secondColor);
  margin-bottom: 20px;
}

.dboard .cards {
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 12px;
  padding: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.dboard .cards:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.dboard .cards-title {
  font-weight: 600;
  color: var(--themeMain);
}

.dboard .cards-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dboard .icon-glow {
  color: var(--secondColor);
  font-size: 24px;
  transition: color 0.3s;
}

.dboard .icon-glow:hover {
  color: var(--success);
}

.dboard h2 {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondText);
}

/* Specific card styles */
.dboard .order-card {
  border-left: 5px solid var(--secondColor);
}

.dboard .payment-card {
  border-left: 5px solid var(--success);
}

.dboard .customer-card {
  border-left: 5px solid var(--specialPrice);
}

.dboard .completed-order-card {
  border-left: 5px solid #6F6AF8;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .dboard  .cards {
      padding: 10px;
  }
  .dboard  h2 {
      font-size: 1.5rem;
  }
}

/* Enhanced Search Input */
.animated-search-input {
  border-radius: 25px;
  background-color: #f0f0f0;
  padding-left: 15px;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

}

.animated-search-input:focus {
  width: 80%;
  background-color: #fff;
  border-color: #ff8e01;
  box-shadow: 0 0 15px rgba(255, 142, 1, 0.8);
  outline: none;
}

.search-wrapper .custom-search-icon {
  background: linear-gradient(135deg, #012246, #ff8e01);
  color: white;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-wrapper .custom-search-icon:hover {
  transform: scale(1.2);
  background-color: #ff8e01;
  color: #fff;
  box-shadow: 0 0 10px rgba(255, 142, 1, 0.7);
}

/* Fancy Dropdown */
.fancy-select-dropdown {
  border-radius: 15px;
  
  backdrop-filter: blur(5px);
  color: #0a0a0a;
 
  transition: background 0.4s ease;
}
.fancyy-select-dropdown {
  margin-top: 5px;
  border-radius: 15px;
  width: 300px;
  backdrop-filter: blur(5px);
  color: #0a0a0a;
  
  transition: background 0.4s ease;
}

.fancy-select-dropdown option {
  color: #000;
  background: rgba(255, 255, 255, 0.85);
}

.fancy-select-dropdown:hover {
  background: rgba(255, 255, 255, 0.3);
}

.fancyy-select-dropdown:focus {
  box-shadow: 0 0 10px rgba(255, 142, 1, 0.5);
  border: 2px solid #ff8e01;
}
.fancy-select-dropdown:focus {
  box-shadow: 0 0 10px rgba(255, 142, 1, 0.5);
  border: 2px solid #ff8e01;
}

/* Animated Clear Button */
.animated-clear-btn {
  border-radius: 20px;
 padding: 8px 25px;
  background: linear-gradient(135deg, #cc0c39, #ff4e4e);
  color: white;
  transition: all 0.4s ease;
  position: relative;
}
.animatedd-clear-btn {
  border-radius: 20px;
 padding: 8px 25px;
  background: linear-gradient(135deg, #cc0c39, #ff4e4e);
  color: white;
  transition: all 0.4s ease;
  position: relative;
}

.animated-clear-btn:hover {
  background: linear-gradient(135deg, #ff4e4e, #cc0c39);
  box-shadow: 0 0 15px rgba(255, 78, 78, 0.6);
  transform: scale(1.1);
  animation: pulsee 1s infinite;
}
.animatedd-clear-btn:hover {
  background: linear-gradient(135deg, #ff4e4e, #cc0c39);
  box-shadow: 0 0 15px rgba(255, 78, 78, 0.6);
  transform: scale(1.1);
  animation: pulsee 1s infinite;
}

@keyframes pulsee {
  0% {
    box-shadow: 0 0 10px rgba(255, 78, 78, 0.6);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 78, 78, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 78, 78, 0.6);
  }
}

/* Hover Animation for Dropdown Options */
.fancy-select-dropdown option:hover {
  background: linear-gradient(135deg, #ff8e01, #012246);
  color: white;
}
@media (max-width: 768px) {
  .fancy-select-dropdown {
    margin-top: 10px;
    width: 165px;
  }
  .animated-clear-btn {
   position: relative;
   bottom: 37px;
   border-radius: 10px;
   width: 49px;
   padding: 4px ;
   left: 3px;
  }
  .fancyy-select-dropdown {
    margin-top: 10px;
    width: 170px;
  }
  .animatedd-clear-btn {
   position: relative;
   bottom: 61px;
   border-radius: 10px;
   width: 60px;
   padding: 6px ;
   left: 3px;
  }
}
.td1{
  border-radius: 50px;
  
}
.bg-light-success{
  background-color: #62af62;
}

.title-p {
  font-weight: bold;
  color: #012246;
}


.form-control.bg {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.form-control.bg:focus {
  border-color: #ff8e01;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.section {
  background: linear-gradient(180deg, #f8f8f8, #fff);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.black-icon-dot{
  width: 12px;
  height: 12px;
}
.w-50px{
  width: 50px;
}
.light-bg{
  background: #b8b8b82e;
}
.copy-zoom {
  animation: zoom 0.3s ease;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(2);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/* Container */
.orderbg .container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Heading */
.orderbg h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

/* Search bar */
.orderbg .search-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Filter Buttons */
.orderbg .filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.orderbg .filter-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.orderbg .filter-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Summary Section */
.orderbg .summary {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.orderbg .summary p {
  font-weight: bold;
}

.orderbg .summary span {
  color: #007bff;
}

/* Order Card */
.orderbg .order-card {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.orderbg .order-id {
  font-weight: bold;
  margin-bottom: 5px;
}

.orderbg .order-info {
  font-size: 0.9rem;
  color: #666;
}

.orderbg .order-amount {
  font-weight: bold;
  margin-top: 10px;
}

.orderbg .status-chips {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.orderbg .chip {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.8rem;
  color: white;
}

.orderbg .chip-unfulfilled {
  background-color: #ff9800;
}

.orderbg .chip-pending {
  background-color: #2196f3;
}

.orderbg .chip-paid {
  background-color: #4caf50;
}

.orderbg .order-shipping {
  font-size: 0.9rem;
  color: #666;
  margin-top: 10px;
}
/* Mobile view */
@media (max-width: 768px) {
  .side-menu {
    position: fixed;
    left: 0;
    width: 100px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
  }
  .side-menu i{
   font-size: 15px;
   color: #000;
  }
  .content{
    left: 0;
    width: 100%;
  }

  .side-menu.inactive {
    transform: translateX(0);
  }

  .toggle-button {
    background: none;
    /* position: absolute;
    top: 10px;
   right: 160px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #000;
    z-index: 1; */
  }
  .icon-right {
   position: relative;
  bottom: 18px;
   left: 190px;
   
   border-radius: 50%;
  
   z-index: 1;
  }
  .icon-rightt {
  display: none;
  }
  .pfont{
    position: relative;
    right: 20px;
    color: #000;
    font-size: 17px;
    font-style: italic;
    top: 1px;
  
  }
  
}
.pfont{
 margin-left: 20px;

}
.pfont:hover{
  cursor: pointer;
 color: var(--themeMain);
}
.prevNextbtn{
  position: fixed;
  bottom:0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%
}
i{
  object-fit: contain;
}
/* offer section */
.offer-strip{
  /* position: absolute; */
  top: 0px;
  width: 100%;
  left: 0px;
  background-color: red;
  color: rgb(255, 255, 255);
  padding: 2px 0px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
  text-align: center;
}
@keyframes waveAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
.wave-bg {
  position: relative;
  background-color: white; 
  overflow: hidden;
}
.wave-bg::before,
.wave-bg::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 100px;
  background: rgba(128, 128, 128, 0.2); 
  opacity: 0.6;
  border-radius: 50%;
  animation: waveAnimation 3s infinite ease-in-out;
}
.wave-bg::before {
  top: -30px; 
  left: -50%; 
}
.wave-bg::after {
  top: -20px; 
  left: 0;
  animation-delay: 1.5s; 
}
.text-wrap-auto{
  text-wrap: auto;
}
@keyframes scrollAlternate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}

.scroll-container {
  overflow: hidden; /* Ensures only visible content is shown */
  white-space: nowrap; /* Prevents wrapping to a new line */
}

.scroll-content {
  display: inline-flex; /* Keeps all items in a single row */
  animation: scrollAlternate 5s ease-in-out infinite alternate;
}
.fw-900{
  font-weight: 900 !important;
}